import {  ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { NbLoginComponent, NbAuthService } from '@nebular/auth';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tqp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends NbLoginComponent {
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  rememberMe = false;

  constructor(
    private authService: AuthService,
    private nbAuthService: NbAuthService,
    public  cd: ChangeDetectorRef,
    public router: Router) {
    super(
      nbAuthService,
      {},
      cd, 
      router,
    );

    this.showMessages = {     // show/not show success/error messages
      success: true,
      error: true,
    };
  }

  redirectToRegister() {
    this.router.navigate(['auth/register']);
  }

  login() {
    console.log('USER USER USER');
    console.log(this.user);
    console.log('USER USER USER');
    this.authService.signIn(this.user).then( () => {
      console.log('SUCCESSFUL SIGN IN');
      
      this.router.navigate(['pages/dashboard']);
    });
  }
}
