import {  ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { NbRegisterComponent, NbAuthService } from '@nebular/auth';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { RegisterService } from './register.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tqp-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends NbRegisterComponent implements OnDestroy {
    subcriptions: Subscription[] = [];
    redirectDelay: number = 0;
    showMessages: any = {};
    strategy: string = '';
  
    submitted = false;
    errors: string[] = [];
    messages: string[] = [];
    user: any = {};

  constructor(
    private authService: AuthService,
    private nbAuthService: NbAuthService,
    public  cd: ChangeDetectorRef,
    public router: Router,
    ) {
    super(
      nbAuthService,
      {},
      cd, 
      router,
    );

    this.showMessages = {
      success: true,
      error: true,
    };
  }
  redirectToLogin() {
    this.router.navigate(['auth/login']);
  }

  verifyUserEmail() {
    const obs = this.authService.verifyNewUserEmail(this.user, this.user.companyCode).subscribe( email => {
      console.log('VERIFYING USER 23 23 23 23 '); 
      const validEmail = !!email;
      if (validEmail) {
        this.register();
      } else {
        console.log('DID NOT RECOGNIZE PROVIDED EMAIL OR COMPANY CODE');
        this.errors.push(
          `Company code ${this.user.companyCode} or email ${this.user.email} not recognized`,
        );
      }
    });
    this.subcriptions.push(obs);
  }

  register(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    this.authService.createUser(this.user);
  }
  ngOnDestroy() {
    this.subcriptions.map( o => o.unsubscribe());
  }
}
