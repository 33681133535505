import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of, throwError } from 'rxjs';
import { switchMap} from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class RegisterService {

    constructor(
        private db: AngularFirestore,
    ) {

    }

    checkUsers(userEmail: string, companyCode: string) {
        console.log('register service check users');
        const registerUser = this.db.doc(`registeredAccounts/${companyCode}`).valueChanges();
        return registerUser;
    }

}

