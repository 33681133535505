import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of, throwError, Subscription } from 'rxjs';
import { take, switchMap, map} from 'rxjs/operators';
import * as firebase from 'firebase';
import { RegisterService } from './register/register.service';


interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  createdAt?: any;
}


@Injectable({ providedIn: 'root' })
export class AuthService {

  user: Observable<User>;
  subscriptions: Subscription[] = [];
  userUid = '';
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private registerService: RegisterService,
  ) {
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            this.userUid = user.uid;
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          } else {
              console.log('INVALID USER');
            return of(null);
          }
        }),
        catchError( (err) => {
            this.router.navigate(['auth/login']);
            return throwError(`Error on auth => ${err}`);
        }),
      );
    }

    
  verifyNewUserEmail(userEmail: string, companyCode: string) {
    console.log('AUTH SERVICE VERIFY USER');
    console.log(companyCode);
    console.log('AUTH SERVICE VERIFY USER');
    const registeredUsers = this.registerService.checkUsers(userEmail, companyCode);
    return registeredUsers;
  }

  createUser(user: any) {
    return this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
    .then( (newUser) => {
      const obs = this.verifyNewUserEmail(user.email, user.companyCode)
      .pipe(
        take(1),
        map( (account) => account['emails']),
      )
      .subscribe( validEmails => {
        const validEmail = !!Object.keys(validEmails).includes(newUser.user.email);

        if (validEmail) {
          this.updateUserData(
            newUser.user,
            {
                displayName: user.fullName || '',
                createdAt: ''
            }
          ).then( () => {
            this.router.navigate(['pages/dashboard']);
          }).catch( error => {
            console.log(`Error updating user information`);
            console.log(error);
          });
        } else {
          console.log('DID NOT RECOGNIZE EMAIL ADDRESS 123 321');
          this.signOut();
        }
      });
      // this.subscriptions.push(obs);
    })
    .catch( (error) => {
        console.log('Error registering new user');
        console.log(error);
        console.log('Error registering new user');
    });
  }

  signIn(user: any) {
    return this.afAuth.auth.signInWithEmailAndPassword(user.email, user.password).catch( (error) => {
        console.log('Error logging in');
        console.log(error);
        console.log('Error logging in');
        //todo create session and current session
      });
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
        this.router.navigate(['auth/login']);
    }).catch( error => {
        console.log('FAILED TO LOGOUT');
    });
  }

  private createUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    }
    // userRef.set(data);
    return userRef.set(data, { merge: true });

  }

  private updateUserData(user, extraDetails?: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: extraDetails.displayName || '',
      createdAt: extraDetails.createdAt || null,
      photoURL: user.photoURL
    }
    return userRef.set(data, { merge: true });

  }

}