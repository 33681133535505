import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { LayoutService } from '../../../@core/data/layout.service';
import { AuthService } from '../../../@core/auth/auth.service';

import { filter, map} from 'rxjs/operators';
import { Observable, Subscription, of, throwError } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  observables: Subscription[] = [];
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserService,
              private analyticsService: AnalyticsService,
              private layoutService: LayoutService,
              private authService: AuthService) {
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  ngOnInit() {
    const obs = this.authService.user.subscribe( (user) => {
      this.user = user;
    });
    this.observables.push(obs);


    this.userService.getUsers()
      .subscribe((users: any) => this.user = users.nick);
    
      this.menuService.onItemClick()
      .subscribe(({item}) => {
        if (item.title === 'Log out') {
          this.authService.signOut();
        }
      });
  }

  ngOnDestroy() {
    this.observables.map( o => o.unsubscribe());
  }

}
