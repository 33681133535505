import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild , ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService} from '../auth.service';

import { Observable } from 'rxjs/Observable';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class PagesAuthGuard implements CanActivateChild {
  constructor(
      private authService: AuthService, 
      private router: Router,
    ) {}

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {

    this.authService.user.subscribe( user => {
      const isLoggedIn = !!user;
      if (!isLoggedIn) {
            console.log('access denied');
      }
    });

    return this.authService.user.pipe(
      take(1),
      map((user) => !!user),
      tap((loggedIn) => {
        if (!loggedIn) {
          console.log('access denied');
          console.log(loggedIn);
          console.log('access denied');
          this.router.navigate(['auth/login']);
        } else {

        }
      }),
    ); 
  }
}
