import { Injectable } from '@angular/core';
// import { NavigationEnd, Router } from '@angular/router';
// import { Location } from '@angular/common';
// import { filter } from 'rxjs/operators';

import {
    AngularFirestore,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

// import { Observable } from 'rxjs/Observable';
// import * as firebase from 'firebase/app';

@Injectable()
export class FirestoreService {
    authState: any = null;

    constructor(
      private afs: AngularFirestore,
      private afAuth: AngularFireAuth,
      ) {
        this.afAuth.authState.subscribe((auth) => {
            this.authState = auth;
        });
    }

    anonymousLogin() {
        return this.afAuth.auth.signInAnonymously()
        .then((user) => {
            this.authState = user;
        })
        .catch(error => console.log(error));
    }

    readCollection(collectionPath: string) {
        console.log(`READ ${collectionPath} ...`);
        return this.afs.collection<any>(collectionPath);
    }

    queryCollection(collectionPath: string, query) {
        console.log(`Query ${collectionPath} ... ${query}`);
        return this.afs.collection<any>(
            collectionPath,
            ref => {
                return ref
                .where('details.keywords', 'array-contains', query);
            });
    }

}
